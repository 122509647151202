<template>
    <div class="login-box">
        <div class="login-logo">
            <img src="@/assets/logo.svg" alt="ANASAP Logo">
        </div>
        <!-- /.login-logo -->
        <div class="login-box-body">
            <p class="login-box-msg">Email byl úspěšně ověřen!</p>
            <form>
            <div class="row">
                <div class="col-xs-12">
                <a href="/#/login"><button type="button" class="m-auto anasap-btn">Přihlásit</button></a>
                </div>
                <!-- /.col -->
            </div>
            </form>

        </div>
        <!-- /.login-box-body -->
    </div>
</template>

<script>
const name = 'Verified';

export default {
    name
}
</script>

<style>
    @import "../assets/scss/login.scss";
</style>
